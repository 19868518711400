import MoonstoneImage from '../../assets/img/sq_moonstone_necklace.png';
import GenImage from '../../assets/img/1-gen.webp';
import { GalleryItem } from './GalleryItem';

const ImageArray = [
  {
    productImage: MoonstoneImage,
    landscapeImage: GenImage,
    title: 'Moonstone Necklace'
  }
];

// Main Gallery Component
export const Gallery = () => {
  return (
    <div className="min-h-screen bg-white">
      <main className="max-w-6xl px-4 py-8 mx-auto">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 place-items-center">
          {ImageArray.map((_, index) => (
            <GalleryItem 
              key={index}
              productImage={MoonstoneImage}
              landscapeImage={GenImage}
              index={index}
            />
          ))}
        </div>
      </main>
    </div>
  );
};