import { useRouteError } from "react-router-dom";
import { Header } from "../App/Components/Header";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div>
      <Header />
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-col w-full h-full pt-24 text-center">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
    </div>
  );
};
