import { RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";
import { ErrorPage } from "../routes/Error";
import { Home } from "../routes/Home/Home";
import { Gallery } from "../routes/Gallery/Gallery";
import { Header } from "./Components/Header";
import { AnimatePresence, motion } from "framer-motion";
import { Footer } from "./Components/Footer";

import React, { ReactNode } from "react";
import { About } from "../routes/About/About";

interface TransitionWrapperProps {
  children: ReactNode;
  isHome?: boolean;
}

export const TransitionWrapper = ({ children, isHome = false }: TransitionWrapperProps) => {
  const location = useLocation();

  return (
    <div>
      {!isHome && <Header/>}
      <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,  // Fast enough to feel snappy but still visible
          ease: "easeInOut"
        }}
        className="w-full min-h-screen"
        >
        {children}
      </motion.div>
      </AnimatePresence>
      {!isHome && <Footer/>}
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <TransitionWrapper isHome={true}><Home /></TransitionWrapper>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/gallery",
    element: <TransitionWrapper><Gallery /></TransitionWrapper>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <TransitionWrapper><About/></TransitionWrapper>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/studio",
    element: <TransitionWrapper><div className="absolute -translate-x-1/2 left-1/2 top-1/2"> {"studio page coming soon :)"} </div></TransitionWrapper>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <TransitionWrapper><div className="absolute -translate-x-1/2 left-1/2 top-1/2"> {"contact page coming soon :)"} </div></TransitionWrapper>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/product",
    element: <TransitionWrapper> product page </TransitionWrapper>,
    errorElement: <ErrorPage />,
  },
]);

export const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
