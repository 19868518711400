import { Link } from "react-router-dom";
import { useState } from "react";
import { Menu, X } from "lucide-react";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="max-w-6xl px-4 py-8 mx-auto sm:mb-12">
      <nav className="relative flex flex-col items-center justify-between gap-2 sm:flex-row">
        <div className="flex-1 hidden sm:block">
          {/* Spacer for centering logo */}
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="absolute right-0 p-2 sm:hidden"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <X className="w-6 h-6 text-gray-600" />
          ) : (
            <Menu className="w-6 h-6 text-gray-600" />
          )}
        </button>

        {/* Center Logo */}
        <Link to="/gallery">
        <div className="flex-1 text-center">
          <h1 className="text-3xl font-medium">
            <span className="block text-green-700">KODAMA</span>
            <span className="block text-pink-400">MARKET</span>
          </h1>
        </div>
        </Link>


        {/* Navigation Links - Desktop */}
        <div className="justify-end flex-1 hidden gap-8 sm:flex">
          <Link
            to="/gallery"
            className="text-gray-600 transition-colors hover:text-gray-900"
          >
            gallery
          </Link>
          {/* <Link
            to="/studio"
            className="text-gray-600 transition-colors hover:text-gray-900"
          >
            studio
          </Link> */}
          <Link
            to="/about"
            className="text-gray-600 transition-colors hover:text-gray-900"
          >
            about
          </Link>
          <Link
            to="/contact"
            className="text-gray-600 transition-colors hover:text-gray-900"
          >
            contact
          </Link>
          <Link
            to="/cart"
            className="text-gray-600 transition-colors hover:text-gray-900"
          >
            cart
          </Link>
        </div>

        {/* Mobile Menu */}
        <div
          className={`
            ${isMenuOpen ? "flex" : "hidden"}
            flex-col
            items-center
            gap-4
            w-full
            pt-4
            sm:hidden
          `}
        >
          <Link
            to="/gallery"
            className="text-gray-600 transition-colors hover:text-gray-900"
            onClick={() => setIsMenuOpen(false)}
          >
            gallery
          </Link>
          {/* <Link
            to="/studio"
            className="text-gray-600 transition-colors hover:text-gray-900"
            onClick={() => setIsMenuOpen(false)}
          >
            studio
          </Link> */}
          <Link
            to="/about"
            className="text-gray-600 transition-colors hover:text-gray-900"
            onClick={() => setIsMenuOpen(false)}
          >
            about
          </Link>
          <Link
            to="/contact"
            className="text-gray-600 transition-colors hover:text-gray-900"
            onClick={() => setIsMenuOpen(false)}
          >
            contact
          </Link>
          <Link
            to="/cart"
            className="text-gray-600 transition-colors hover:text-gray-900"
            onClick={() => setIsMenuOpen(false)}
          >
            cart
          </Link>
        </div>
      </nav>
    </header>
  );
};