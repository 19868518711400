import { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface GalleryItemProps {
  productImage: string;
  landscapeImage: string;
  index: number;
  title?: string;
}

export const GalleryItem: React.FC<GalleryItemProps> = ({ 
  productImage, 
  landscapeImage, 
  index,
  title 
}) => {
  const [isShowingLandscape, setIsShowingLandscape] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  // Detect touch device
  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);

  const handleInteraction = useCallback(() => {
    if (isTouchDevice) {
      setIsShowingLandscape(prev => !prev);
    }
  }, [isTouchDevice]);

  return (
    <motion.div
      className="relative w-5/6 overflow-hidden rounded-lg aspect-square group"
      onHoverStart={() => !isTouchDevice && setIsShowingLandscape(true)}
      onHoverEnd={() => !isTouchDevice && setIsShowingLandscape(false)}
      onClick={handleInteraction}
      initial={false}
    >
      {/* Product Image with subtle animation */}
      <motion.div
        animate={{ scale: isShowingLandscape ? 1.05 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <img
          src={productImage}
          alt={title || `Gallery item ${index + 1}`}
          className="object-cover w-full h-full"
        />
      </motion.div>

      {/* Landscape Image Overlay */}
      <AnimatePresence>
        {isShowingLandscape && (
          <motion.div
            className="absolute inset-0 w-full h-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <img
              src={landscapeImage}
              alt={`Inspiration for ${title || `item ${index + 1}`}`}
              className="object-cover w-full h-full"
            />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Interaction Indicator */}
      <div 
        className={`
          absolute bottom-4 right-4 
          w-8 h-8 
          rounded-full 
          bg-white/80 
          flex items-center justify-center
          transform transition-all duration-300
          ${isShowingLandscape ? 'rotate-180 opacity-0' : 'rotate-0 opacity-100'}
        `}
      >
        <span className="text-sm">
          {isTouchDevice ? '↺' : '↗'}
        </span>
      </div>
      <div 
        className={`
          absolute bottom-4 right-4 
          w-8 h-8 
          rounded-full 
          bg-white/80 
          flex items-center justify-center
          transform transition-all duration-300
          ${!isShowingLandscape ? 'rotate-180 opacity-0' : 'rotate-0 opacity-100'}
        `}
      >
        <span className="text-sm">
          {isTouchDevice ? '↗' : '↺'}
        </span>
      </div>
    </motion.div>
  );
};