import { Calendar } from "lucide-react";

export const About = () => {
  return (
    <div className="min-h-screen bg-white">
      <main className="max-w-3xl px-4 mx-auto">
        <div className="space-y-8">
          {/* Header Section */}
          <div className="text-center">
            <h1 className="text-4xl font-medium text-gray-900">About Me</h1>
            <div className="flex items-center justify-center mt-2 text-sm text-gray-500">
              <Calendar className="w-4 h-4 mr-1" />
              <span>Last updated: November 1, 2024</span>
            </div>
          </div>

          {/* Introduction */}
          <div className="prose prose-gray lg:prose-lg">
            <p className="text-lg leading-relaxed">
              Hey! I'm Erin Beachkofski, a creative technologist based in San Francisco. 
              My days are spent as a software developer, but behind the scenes, I'm always scheming up a new project. 
              Sometimes it's something in the physical world, and sometimes it's something in the digital world. 
            </p>
            
            <br/>

            <p className="text-lg leading-relaxed">
              I'm on a mission to make life more playful and interesting, which leads me 
              down all sorts of creative paths. Currently, you might find me:
            </p>

            <br/>

            <ul className="ml-6 space-y-2 list-disc">
              <li>Painting with acrylics (trying out raw canvas as a medium right now)</li>
              <li>Giving thrifted items new life (repurposing cool frames, finding free canvases on the street)</li>
              <li>Sand casting silver into unique jewelry pieces</li>
              <li>Creating indie software projects and hiding them around San Francisco like digital easter eggs</li>
            </ul>

            <div className="mt-8 text-sm italic text-gray-500">
              * this list changes pretty frequently! I have an ambitious mind 
              that's always chasing new ideas. Consider this a snapshot of my current 
              creative adventures rather than a complete portfolio.
            </div>

            <br/>

            <p className="text-lg leading-relaxed">
              This site serves as both my gallery and my motivation to keep creating. 
              It's a space where my tech background meets my artistic endeavors, 
              and I'm excited to share both the finished pieces and the journey of 
              making them.
            </p>

            <br/><br/>

            <p className="text-xl leading-relaxed">
              xoxo, Erin
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};