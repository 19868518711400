import { useEffect, useState } from "react";
import {
  baseCoordsDesktop,
  kodamaMarketDesktopPath,
} from "./data/homePathDesktop";
import { baseCoordsMobile, homePathMobile } from "./data/homePathMobile";
import useWindowSize from "../../utils/useWindowResize";
import { Link } from "react-router-dom";
import { GalleryEntryButton } from "../../App/Components/GalleryEntryButton";
const pathDesktop = kodamaMarketDesktopPath;
const pathMobile = homePathMobile;

const isPortrait = () => window.innerHeight > window.innerWidth;

export const Home = () => {
  const [points, setPoints] = useState<JSX.Element[]>([]);
  const windowSize = useWindowSize();

  useEffect(() => {
    const path = isPortrait() ? pathMobile : pathDesktop;
    const baseCoords = isPortrait() ? baseCoordsMobile : baseCoordsDesktop;
    const coordsRatio = {
      xRatio: windowSize.width / baseCoords.x,
      yRatio: windowSize.height / baseCoords.y,
    };

    const getPoints = (): JSX.Element[] => {
      return path.map((p, index) => {
        const icon = p.brushType === "flower" ? "🌸" : "🌲";
        let left = p.point.x * coordsRatio.xRatio;
        let top = p.point.y * coordsRatio.yRatio;

        if (!isPortrait()) {
          left += window.innerWidth / 6.5;
        } else {
          left += window.innerWidth / 20;
        }

        const style = {
          left: `${left}px`,
          top: `${top}px`,
        };

        return (
          <span key={index} className="absolute" style={style}>
            {icon}
          </span>
        );
      });
    };

    setPoints(getPoints);
  }, [windowSize]);

  return (
    <div>
      <div>{points}</div>
      <Link to="/gallery">
        <GalleryEntryButton />
      </Link>
    </div>
  );
};
